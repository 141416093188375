<script setup>
	const { anchorId, cards } = defineProps({
		anchorId: {
			type: String,
			required: false,
			default: '',
		},
		cards: {
			type: Array,
			required: true,
		},
		attributes: {
			type: Object,
			default: () => ({}),
		},
	});
</script>

<template>
	<section :id="anchorId" class="block block-three-or-four-up-cards-with-links bg-gray-100" tabindex="-1">
		<div class="container">
			<ul class="card-list">
				<li v-for="(card, index) in cards" :key="index" :class="`length-${cards.length}`" class="outer-wrapper">
					<FragmentsCardLink :url="card.url" :image="card.image" :title="card.title"> </FragmentsCardLink>
				</li>
			</ul>
		</div>
	</section>
</template>

<style lang="scss">
	.block-three-or-four-up-cards-with-links {
		.card-list {
			@media (min-width: $mobile) {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin: -10px;
			}
		}

		.outer-wrapper {
			margin-bottom: 20px;

			@media (min-width: $mobile) {
				flex: 0 0 calc(50% - 20px);
				margin: 10px;
			}

			@media (min-width: $tablet) {
				flex: 0 0 calc(25% - 20px);
			}

			&.length-3 {
				@media (min-width: $tablet) {
					flex: 0 0 calc(33.33% - 20px);
				}
			}

			.card-link .mess-image {
				aspect-ratio: 1.4;
			}

			a.card-link:hover {
				.content-wrapper {
					transform: translate(0, -49%);
				}
			}

			.content-wrapper {
				width: min-content;
				margin-top: -50px;
				padding: 20px 20px;

				@media (min-width: $mobile) {
					padding: 20px 36px;
				}

				.card-title {
					margin-bottom: 0;
				}
			}
		}
	}
</style>
